.c-form{
    margin-bottom: 60px;

    .spinner{
        position: relative;

        .thank-you{
        color:$reg-headline;
        font-size: 24px;
        }

        .error-message{
            color: red;
            font-size: 21px;
        }

        .thank-you, 
        .error-message{
            display: none;
            margin: 30px 0;
            position: absolute;
            bottom: 0;
            opacity: .3;
            top: 20%;
            height: max-content;
            transition: 2s all;

            &.active{
                display: flex;
                opacity: 1;
            }
            p{
                letter-spacing: .3px;
                line-height: 32px;
            }

        }

        .spinner-border{
            color: $reg-btn;
            position: absolute;
            top: 30%;
            right: 0;
            left: 0;
            margin: 0 auto;
        }
    }

    form#schedule-appointment{

        .form-control{
            border: none;


            &:focus{
                box-shadow: none;
            }
        }

        input{
            border-radius: 50px;
            padding: 11.5px 22px;
            margin-bottom: 14px;
            letter-spacing: .8px;
            background-color: $reg-input;
            border-color: $reg-input;
            outline: none;
            color: black;

            &:focus{
                background-color:  $reg-focus;
            }
    
            &[type=submit]{
                background-color: $reg-callout;
                margin-top: 34px;
                padding: 11px 34px;
                color: white;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                border: none;
                letter-spacing: 1.75px;
                line-height: 19px;
                transition: .2s all;

                &:hover{
                    background-color: $reg-btn;
                }
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            /* Firefox */
            &[type=number] {
            -moz-appearance: textfield;
            }
    
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $reg-placeholder;
                opacity: 1; /* Firefox */
            }
                
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $reg-placeholder;
            }
                
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $reg-placeholder;
                }
    
            
        }
    
        textarea{
            border-radius: 20px;
            padding: 16px 20px 24px 29px;
            letter-spacing: .8px;
            border-color: $reg-textarea;
            background-color: $reg-textarea;
    
            //RESIZE
            resize: none;

            &:focus{
                background-color:  $reg-focus;
            }

            & + .invalid-feedback{
                margin-top: 4px;
            }
    
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $reg-placeholder;
                opacity: 1; /* Firefox */
            }
              
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $reg-placeholder;
            }
              
            &::-ms-input-placeholder { /* Microsoft Edge */
                color: $reg-placeholder;
              }
        }

        #coffee{
            display: none !important;
            visibility: hidden !important;
        }

        .invalid-feedback{
            margin-top: -10px;
            padding-left: 10px;
            margin-bottom: 12px;
        }
    
        small{
            font-size: 12px;
            letter-spacing: .48px;
            margin-top: 15px;
        }
    }
    
}

