.nav-main{
    min-height: 200px;
    background-color: $blue;

    div:first-child{
        @include media-breakpoint-down(lg){
            padding-bottom: 20px;
        }
    }

    .menu-bar{
        width: 30px;
        height: 4px;
        background-color: white;
        margin: 2px 0;
        border-radius: 20px;
    }

    .navbar-toggler{
        font-family: $roboto-slab;
        border: none;
        display: flex;
        align-items: center;

        &:hover{
            .menu{
                color: $reg-callout;
            }
        }
    
        .menu{
            font-size: 12px;
            font-family: $roboto;
            text-transform: uppercase;
            letter-spacing: .7px;
            margin-top: 3px;
            font-weight: 600;
            color: white;
            transition: .2s all;
        }
    
        &:focus{
            box-shadow: none;
        }
    }

    .logo img{
        height: 100%;
    }

    .header-contact{
        color: $reg-callout;

        .inner-contact{
            padding-right: 96px;
            padding-top: 12px;

            @include media-breakpoint-down(lg){
                padding-right: 0;
            }
        }

        .address{
            font-size: 14px;
            letter-spacing: .35px;
            line-height: 1.4em;
            margin-bottom: 14px !important;
        }

        a{
            color: $reg-callout;
            text-decoration: none;

            &.phone-num{
                font-family: $roboto-slab;
                text-decoration: none;
                font-size: 24px;
                font-weight: 600;
            }

            &.appointment{
                color: white;
                background-color: $reg-callout;
                font-size: 16px;
                font-weight: 600;
                border-radius: 20px;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding-top: .78em;
                padding-bottom: .6em;

                &:hover{
                    background-color: $reg-btn;
                }
            }
            
        }
    }
}

.masthead{

    iframe{
        max-width: 1276px;
        width: 100%;
        height: 600px;
        border-radius: 20px;
        margin-bottom: 30px;

        @include media-breakpoint-down(xl){
            height: 550px;
        }

        @include media-breakpoint-down(md){
            height: 350px;
        }
    }
    img{
       max-width: 1276px;
       width: 100%;
    }
}


#navbarToggleExternalContent{
   background-color: $blue;
   opacity: .9;
   max-width: 1276px;
   width: 100%;
   position: absolute;
   border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px;
   border-top: 2px dotted $reg-hr;
   z-index: 1;

   .h-links{
       padding: 30px 0;
        ul{
            width: 100%;

            @media screen and (max-width: 1023px) {
                padding-left: 100px !important;
                padding-right: 0 !important;
            }

            @media screen and (max-width: 905px) {
                padding-left: 30px !important;
            }

            @media screen and (max-width: 768px) {
                padding-left: 0 !important;
            }

            li{
                text-align: left;

                @include media-breakpoint-down(md){
                    display: flex;
                    justify-content: center;
                }

                a{
                    color: white;
                    font-family: $roboto-slab;
                    font-size: 18px;
                    text-transform: capitalize;
                    max-width: max-content;

                    &:hover{
                        color: $reg-main;
                        text-decoration: underline;
                    }
                }
            }
        }
   }

   
}

