.hero{
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    background: $blue;


    + .after-slider{
        margin-top: -60px;
    }

    #t-heading{
        letter-spacing: -0.48px;
        font-size: 46px;
        font-weight: 600;
        color: white;
    }

    a{
        font-size: 10px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 20px;
        padding: 6px 38px;
        background: $reg-btn;
        color: white;
        margin-left: 4px;
        margin-right: 2px !important;
    }

    .swiper-container {
        width: 100%;
        height: 200px;
        margin-bottom: 14px;
        overflow: hidden;

        @include media-breakpoint-down(md){
            height: 100%;
            display: flex;
        }
    
        .swiper-slide{
            font-size: 20px;
            
            @include media-breakpoint-down(lg){
                align-self: center;
            }
    
            .inner-slide{
                padding: 0 150px;

                @include media-breakpoint-down(lg){
                    padding: 0 30px;
                }
                h3{
                    font-size: 1.25rem;
                    color: white;
                    font-family: $roboto;
                    padding-bottom: 13px;
                    line-height: 32px;
                    letter-spacing: 1.2px;
                }
                
            }
        }

    }
        .swiper-pagination{
                bottom: 20px;
                position: relative;
        
                .swiper-pagination-bullet-active{
                    background: $reg-btn !important;
                }
                .swiper-pagination-bullet{
                    background: white;
                    width: 18px;
                    height: 18px;
                    margin: 0 10px !important;
                    opacity: 1;

        }
    }
}
